import React from 'react';
import styles from './index.module.less';
import { Button, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export const CommonAlert = ({
  open,
  title,
  desc,
  btnTitle,
  onCloseAction,
  onConfirmClick,
}: {
  open?: boolean;
  title?: string;
  desc?: string;
  btnTitle?: string;
  onCloseAction?: () => void;
  onConfirmClick?: () => void;
}) => {
  return (
    <Modal
      open={open}
      onCancel={() => onCloseAction()}
      destroyOnClose={true}
      closeIcon={<CloseOutlined style={{ color: 'var(--app-text-color)' }} />}
      footer={null}
      className={styles.content}
    >
      <div className={styles.alertContainer}>
        {title && <h1 className={styles.title}>{title}</h1>}
        {desc && <p className={styles.desc}>{desc}</p>}
        {btnTitle && (
          <Button className={styles.btn} onClick={onConfirmClick}>
            {btnTitle}
          </Button>
        )}
      </div>
    </Modal>
  );
};
