import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { App, Checkbox, Modal, Spin, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import PhotoGuideContent from '../PhotoGuideContent';
import {
  fetchHairstyleCreateData,
  fetchHairstyleCreateResultsData,
  fetchHairstyleLastImageData,
  fetchHairstyleRecordsBalanceData,
  updateImageData,
} from '@/lib/service';
import { CommonAlert } from '@/components/Common/CommonAlert';
import { BaseResponse } from '@/types/web.interface';
import { HairCreateResultModel } from '@/types/hairstyle';
import { useUserProvider } from '@/context/UserProvider';
import { imgUrl } from '@/shared/utils';
import dayjs from 'dayjs';
import HairShareAlertContent from '@/components/Common/HairShareAlert';
import InsufficientCoinsAlert from '@/components/Common/InsufficientCoinsAlert';
import { useConfigProvider } from '@/context/ConfigProvider';

const UploadImageContent = ({
  openId,
  handleClose,
}: {
  openId?: any;
  handleClose?: () => void;
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { message } = App.useApp();
  const { isLogin, onOpenLoginModal, onChangedLoginView } = useUserProvider();

  const [openPhotoGuide, setOpenPhotoGuide] = useState(false);
  const [checkVal, setCheckVal] = useState(true);
  const inputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<any>(null);
  const [openAlertData, setOpenAlertData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [stepIndex, setStepIndex] = useState(1);

  const [counter, setCounter] = useState(0);
  const [result, setResult] = useState<BaseResponse<HairCreateResultModel[]>>({});
  const { onCreditsChanged } = useUserProvider();
  const [openShare, setOpenShare] = useState(false);
  // const [feedbackAlert, setFeedbackAlert] = useState(false);
  const { isMobile } = useConfigProvider();
  const [shareTipShow, setShareTipShow] = useState(true);

  const loadResultData = (create_ids: any) => {
    fetchHairstyleCreateResultsData(create_ids)
      .then((res) => {
        if (res?.code === 0) {
          if (res?.data?.length > 0 && res?.data[0]?.status === 'FINISHED') {
            setResult(res);
            setCounter(-1);
            setStepIndex(3);
          } else if (res?.data?.length > 0 && res?.data[0]?.status === 'FAILED') {
            message?.error('The build failed, please try again.');
            setStepIndex(1);
          }
        } else {
          message?.error(res?.message);
        }
      })
      .catch((e) => {
        message?.error(e?.message);
      });
  };

  const createImage = (image_url: string) => {
    fetchHairstyleCreateData(image_url, [openId])
      .then((res) => {
        if (res.code === 30001) {
          setLoading(false);
          setOpenAlertData({
            title: `Oops...You're out of credits`,
            desc: `Subscribe now to get credits and continue trying on the latest hairstyles!`,
            btnTitle: `Subscribe Now`,
            path: `/plan?ref=${router?.asPath}`,
          });
          return;
        } else if (res.code === 30002) {
          setLoading(false);
          setOpenAlertData({
            title: `Oops...You're out of credits`,
            desc: `You're running low on credits. Upgrade your subscription to get more Hairpaca Credits and keep exploring new hairstyles!`,
            btnTitle: `Upgrade Now`,
            path: `/plan?ref=${router?.asPath}`,
          });
          return;
        } else if (res.code === 30003) {
          setLoading(false);
          setOpenAlertData({
            title: `Oops...You're out of credits`,
            desc: `Your monthly credits have been used up. Please wait for the next subscription cycle to receive a new set of credits.`,
            btnTitle: `OK`,
          });
          return;
        } else if (res.code === 30004) {
          setLoading(false);
          handleClose();
          // setFeedbackAlert(true);
          setOpenAlertData({
            title: `Oops...You're out of credits`,
            desc: `Subscribe now to get credits and continue trying on the latest hairstyles!`,
            btnTitle: `Subscribe Now`,
            path: `/plan?ref=${router?.asPath}`,
          });
          return;
        }

        setResult(res);
        if (res?.code === 0) {
          loadBanaceList();
          setLoading(false);
          setStepIndex(2);
          setCounter(1);
        }
      })
      .catch((e) => {
        setLoading(false);
        message.error(e?.message);
      });
  };

  const updateImage = () => {
    if (!isLogin) {
      onChangedLoginView(true);
      onOpenLoginModal(true);
    }
    // if (creditsCount < 2) {
    //   setOpenAlertData({
    //     title: `Oops...You're out of credits`,
    //     desc: `Subscribe now to get more credits and continue trying on the latest hairstyles!`,
    //     btnTitle: `Subscribe Now`,
    //     path: `/plan?ref=${router?.asPath}`,
    //   });
    //   return
    // }

    if (imageUrl.startsWith('https://')) {
      createImage(imageUrl);
      return;
    }

    if (loading) {
      return;
    }
    setLoading(true);
    updateImageData(imageFile, checkVal ? 'lib' : 'mine')
      .then((res) => {
        if (res?.code === 0) {
          createImage(res?.data?.image_url);
        } else {
          setLoading(false);
          message?.error(res?.message);
        }
      })
      .catch((e) => {
        message?.error(e?.message);
        setLoading(false);
      });
  };

  const loadBanaceList = () => {
    fetchHairstyleRecordsBalanceData()
      .then((res) => {
        if (res?.code === 0) {
          onCreditsChanged(res?.data?.balance);
        }
      })
      .catch((e) => {});
  };

  const onCheckboxChange = (e: any) => {
    setCheckVal(e.target.checked);
  };

  const handleImageChange = (e: any) => {
    e.preventDefault();
    handleFile(e.target.files[0]);
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    handleFile(event.dataTransfer.files[0]);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleFile = (file: File) => {
    if (file) {
      const fileSizeInBytes = file.size;
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
      if (fileSizeInBytes > maxSizeInBytes) {
        message.error(t('Your image exceeds the size limit of 10MB.'));
        if (inputRef.current) {
          inputRef.current.value = '';
        }
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result;
        if (typeof result === 'string') {
          setImageUrl(result);
          setImageFile(file);
        }
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (counter !== -1) {
        const tempList = result?.data?.filter((item) => item.status === 'PENDING');
        if (tempList?.length > 0) {
          const idList = tempList.map((item) => item.create_id);
          setCounter((prevCounter) => prevCounter + 1);
          loadResultData(idList);
        }
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [counter]);

  const handleDownload = async () => {
    if (result?.data?.length === 0) {
      return;
    }

    try {
      const response = await fetch(result?.data[0]?.result_pic);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `Hairpaca_${dayjs().format('YYYYMMDDHHmmss')}.png`;

      link.click();
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      // console.error('Error downloading image:', error);
    }
  };

  const loadLastImageData = () => {
    fetchHairstyleLastImageData()
      .then((res) => {
        if (res?.code === 0 && res?.data?.length > 0) {
          setImageUrl(res?.data);
        }
      })
      .catch((e) => {});
  };

  useEffect(() => {
    if (openId !== null && openId !== undefined) {
      setImageFile(null);
      setImageUrl(null);
      setLoading(false);
      setResult(null);
      setStepIndex(1);
      loadLastImageData();
      setCheckVal(true);
    }
  }, [openId]);

  return (
    <>
      <Modal
        open={openId !== null}
        closeIcon={<CloseOutlined style={{ color: 'var(--app-text-color)' }} />}
        onCancel={handleClose}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        footer={null}
        maskClosable={false}
        style={{ padding: 0 }}
        destroyOnClose={true}
      >
        <Spin spinning={loading && stepIndex === 1}>
          <div className={styles.groundContent} id={'createImageContent'}>
            {stepIndex === 1 && (
              <div className={styles.content}>
                <div className={styles.titleContainer}>{t('Upload a Selfie')}</div>

                <div className={styles.subTitleContainer}>
                  <span>
                    {t(
                      'Upload your selfie to instantly try on the hairstyles. Learn more from photo guide',
                    )}
                  </span>
                  {` `}
                  <span
                    style={{ color: '#68F3F6', cursor: 'pointer' }}
                    onClick={() => {
                      setOpenPhotoGuide(true);
                    }}
                  >
                    {t('photo guide')}
                  </span>
                </div>

                <div
                  className={styles.pictureContainer}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onClick={() => inputRef?.current?.click()}
                >
                  <input
                    ref={inputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                  />
                  {imageUrl?.length > 0 ? (
                    <div className={styles.imgContainer}>
                      <img className={styles.image} src={imageUrl} alt="" />
                      <img
                        className={styles.reload}
                        src={'/images/hairstyle/reload-icon.webp'}
                        alt="reload-icon"
                      />
                    </div>
                  ) : (
                    <div className={styles.demoContainer}>
                      <Image
                        src={'/images/hairstyle/create-example-icon.webp'}
                        alt={''}
                        width={100}
                        height={100}
                      />
                      <Image
                        src={'/images/hairstyle/upgrate-icon.png'}
                        alt={''}
                        width={30}
                        height={30}
                      />
                      <span>{t('Upload Your Selfie')}</span>
                    </div>
                  )}
                </div>

                <div className={styles.descContainer}>
                  <Checkbox
                    defaultChecked={checkVal}
                    checked={checkVal}
                    onChange={onCheckboxChange}
                  >
                    <span>{t('Agree to use this photo for the next try-on.')}</span>
                  </Checkbox>
                </div>

                {imageUrl !== null && (
                  <div className={styles.btnContainer}>
                    <div className={styles.btnContent} onClick={() => updateImage()}>
                      <span className={styles.tryOn}>{t('Try-On Now')}</span>

                      <div className={styles.iconContent}>
                        <Image
                          src={'/images/hairstyle/credit_icon.png'}
                          alt={'credit_icon'}
                          width={20}
                          height={20}
                        />
                        <span>-2</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {stepIndex === 2 && (
              <div className={styles.loadingContent}>
                <div className={styles.topContainer}>
                  <img src="/images/hairstyle/hair-loading.gif" alt="hair-loading" />
                  <span>{t('Your new hairstyle is now being styled!')}</span>
                </div>
                <div className={styles.bottomContainer}>
                  <span>{`Find it in `}</span>
                  <a
                    onClick={() => {
                      router.push('/dashboard');
                    }}
                  >
                    <span style={{ color: '#68F3F6' }}>{`"Dashboard"`}</span>
                  </a>
                  <span>{` > `}</span>
                  <a
                    onClick={() => {
                      router.push('/dashboard');
                    }}
                  >
                    <span style={{ color: '#68F3F6' }}>{`"Creations"`}</span>
                  </a>
                </div>
              </div>
            )}

            {stepIndex === 3 && (
              <div className={styles.resultContent}>
                <div
                  className={styles.resultGroundContent}
                  style={{ backgroundImage: `url(${imgUrl(result?.data[0]?.result_pic, 'mid')})` }}
                />
                <div className={styles.mainContent}>
                  <div className={styles.topContainer}>
                    <img
                      className={styles.topImg}
                      src={imgUrl(result?.data[0]?.result_pic, 'mid')}
                      alt=""
                      loading="lazy"
                    />
                  </div>
                  <div className={styles.bottomContainer}>
                    <img
                      className={styles.btn}
                      style={{ background: '#FFF' }}
                      key={'hairstyle_download_icon'}
                      src={'/images/hairstyle/download-icon-black.png'}
                      alt={'hairstyle_download_icon'}
                      onClick={() => {
                        handleDownload();
                      }}
                    />
                    <Tooltip
                      open={shareTipShow}
                      placement={isMobile ? 'bottom' : 'rightTop'}
                      color={'white'}
                      getPopupContainer={() => document.getElementById('createImageContent')}
                      overlayInnerStyle={{
                        color: 'black',
                        width: isMobile ? '323px' : '390px',
                        fontSize: isMobile ? '14px' : '16px',
                        transform: isMobile ? 'translateX(-60px)' : 'translateX(0px)',
                      }}
                      autoAdjustOverflow={false}
                      title={
                        <div style={{ position: 'relative' }}>
                          <CloseOutlined
                            style={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer' }}
                            onClick={() => {
                              setShareTipShow(false);
                            }}
                          />
                          <div>
                            Wow, love your new cut! 😍✨ Share it and <br />
                            see what everyone thinks! 📸👍
                          </div>
                        </div>
                      }
                    >
                      <img
                        className={styles.btn}
                        style={{ background: '#9873FF' }}
                        key={'hairstyle_share_icon'}
                        src={'/images/hairstyle/share-icon-white.png'}
                        alt={'hairstyle_share_icon'}
                        onClick={() => {
                          setOpenShare(true);
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Spin>
      </Modal>

      <PhotoGuideContent open={openPhotoGuide} handleClose={() => setOpenPhotoGuide(false)} />

      <CommonAlert
        open={openAlertData !== null}
        title={openAlertData?.title}
        desc={openAlertData?.desc}
        btnTitle={openAlertData?.btnTitle}
        onCloseAction={() => setOpenAlertData(null)}
        onConfirmClick={() => {
          if (openAlertData?.path) {
            router.push(openAlertData?.path);
          }
          setOpenAlertData(null);
          handleClose();
        }}
      />

      <HairShareAlertContent
        openType={openShare}
        isGenerate={false}
        yourImage={null}
        dataList={result?.data}
        handleCancel={() => setOpenShare(false)}
      />

      {/* <InsufficientCoinsAlert
        openType={feedbackAlert}
        handleCancel={() => setFeedbackAlert(false)}
        handleClick={() => {
          setFeedbackAlert(false);
          router.push('/feedback');
        }}
      /> */}
    </>
  );
};

export default UploadImageContent;
